exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-blog-index-tsx": () => import("./../../../src/pages/en/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ko-index-tsx": () => import("./../../../src/pages/ko/index.tsx" /* webpackChunkName: "component---src-pages-ko-index-tsx" */),
  "component---src-pages-ko-privacy-policy-tsx": () => import("./../../../src/pages/ko/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-ko-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blog-details.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */)
}

